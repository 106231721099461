<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>和我们，做有挑战的事！</div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            提升与发展
            <div class="hr gradient-default"></div>
          </div>
          <div class="classification row gx-0 gy-4">
            <div
              class="left-container"
              style="margin-right: 0.75rem; width: calc(50% - 0.75rem)"
            >
              <div class="skew-element image left"></div>
            </div>
            <div
              class="right-container mobile"
              style="margin-left: 0.75rem; width: calc(50% - 0.75rem)"
            >
              <div class="skew-element color right"></div>
              <div class="text">
                <div class="title position-relative lh-lg">
                  <div>这里没有职业天花板</div>
                  <div>只有独当一面、开拓创新</div>
                  <div class="mt-3">与高密度的优秀大牛同台竞技交流</div>
                  <div>碰撞出事业的火花</div>
                </div>
              </div>
            </div>
            <div
              class="right-container second-row d-block d-lg-none"
              style="margin-left: 0.75rem; width: calc(48% - 0.75rem)"
            >
              <div class="skew-element image right"></div>
            </div>
            <div
              class="left-container mobile"
              style="margin-right: 0.75rem; width: calc(52% - 0.75rem)"
            >
              <div class="skew-element color left"></div>
              <div class="text">
                <div
                  class="title position-relative lh-lg"
                  style="padding-left: 2rem"
                >
                  <div>如果你有在全球市场担任人工智能、交易、策略、风控等角色的经验</div>
                  <div class="mt-3">
                    或在技术开发领域具备丰富的项目经验，或擅长Rust语言
                  </div>
                  <div class="mt-3">
                    我们将乐于为你展示，助你成为一位务实、进取、与时代志同道合的工程师
                  </div>
                </div>
              </div>
            </div>
            <div
              class="right-container d-none d-lg-block"
              style="margin-left: 0.75rem; width: calc(48% - 0.75rem)"
            >
              <div class="skew-element image right"></div>
            </div>
          </div>
        </div>
      </section>
       <section>
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            我们提供什么
            <div class="hr gradient-default"></div>
          </div>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-5 gy-4">
            <div class="plan col">
              <img
                src="../../assets/img/join/provide-01.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">职业发展计划</div>
                <div class="fs-5 py-3 fw-light">
                  个性化培养计划，让你发挥并超越自身的潜力
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/join/provide-02.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">定期学习计划</div>
                <div class="fs-5 py-3 fw-light">
                  鼓励个人和专业发展，定期组织学习交流活动
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/join/provide-03.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">灵活工作安排</div>
                <div class="fs-5 py-3 fw-light">
                  以结果为导向，你可以灵活地平衡工作与生活
                </div>
              </div>
            </div>
            <div class="plan col">
              <img
                src="../../assets/img/join/provide-04.png"
                alt="非凸科技"
              />
              <div class="text">
                <div class="fs-3 mt-4">丰厚绩效奖励</div>
                <div class="fs-5 py-3 fw-light">
                  通过出色的表现，你可以获取丰厚的绩效奖励
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            热招岗位
            <div class="hr gradient-default"></div>
          </div>

          <div v-for="job in jobs" :key="job.title">
            <job-card
              :campus="job.campus"
              :category="job.category"
              :icons="job.icons"
              :title="job.title"
              :location="job.location"
              :link="job.link"
              :description="job.description"
            >
            </job-card>
          </div>

          <div class="text-center my-5">
            <router-link
              to="/about/social-poster"
              role="button"
              class="text-decoration-none btn btn-outline-danger"
              >了解更多</router-link
            >
          </div>
        </div>
      </section>
      <section class="pb-5 bg-light">
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-1">
            关于面试
            <div class="hr gradient-default"></div>
          </div>

          <div
            class="
              row row-cols-1 row-cols-md-2
              justify-content-center
              gy-5
              gx-5
            "
          >
            <div class="col pe-5">
              <div class="fs-3">基本能力</div>
              <div
                class="fs-5 py-5 fw-light border-bottom border-dark border-2"
              >
                编程语言，系统设计，数据分析，算法策略研究等专业能力考察
              </div>
            </div>
            <div class="col pe-5">
              <div class="fs-3">应变能力</div>
              <div
                class="fs-5 py-5 fw-light border-bottom border-dark border-2"
              >
                能否快速把握问题的关键，并给出答案，还能兼顾现场变化
              </div>
            </div>
            <div class="col pe-5">
              <div class="fs-3">逻辑能力</div>
              <div
                class="fs-5 py-5 fw-light border-bottom border-dark border-2"
              >
                解决问题与获得答案同等重要，让面试官清晰了解你的推理逻辑
              </div>
            </div>
            <div class="col pe-5">
              <div class="fs-3">学习能力</div>
              <div
                class="fs-5 py-5 fw-light border-bottom border-dark border-2"
              >
                能快速学习一样新事物（如新语言），考察你的自驱力、抗压力等
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import JobCard from "../../components/JobCard.vue";
export default {
  name: "SocialJob",
  data() {
    return {
      jobs: [
        {
          title: "算法服务平台开发工程师",
          category: "研发",
          icons: "NEW",
          campus: false,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "设计并开发基于 Rust 的高性能，低时延算法交易系统，数据处理平台，监控运维平台，面向客户的高可用交易工具等。",
          link: "/job/01",
        },
        {
          title: "全栈工程师",
          category: "研发",
          icons: "NEW",
          campus: false,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "基于交易体系（金融类）需要定制开发项目特定需求；负责项目相关产品的设计、研发和维护等。",
          link: "/job/06",
        }, 
        {
          title: "运维工程师",
          category: "研发",
          icons: "NEW",
          campus: false,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "负责公司多产品线的客户技术支持工作，在技术层面和客户进行沟通，及时解决问题，维护项目高效正常运行等。",
          link: "/job/07",
        }, 
        {
          title: "商务经理",
          category: "市场",
          icons: "NEW",
          campus: false,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "服务机构客户，以私募证券类、券商机构为主，针对所负责区域内的金融机构客户进行开发与维护等。",
          link: "/job/08",
        }, 
        {
          title: "品牌公关",
          category: "市场",
          icons: "NEW",
          campus: false,
          location: ["上海", "北京", "深圳", "成都", "徐州"],
          description:
            "根据公司品牌规划，完善品牌物料，参与各类品牌传播活动的策划和执行；熟悉新媒体的运营模式等。",
          link: "/job/09",
        }, 
      ],
    };
  },
  components: {
    JobCard,
  },
};
</script>

<style scoped lang="scss">
.container-lg {
  border-radius: 2rem;
}
.icon {
  font-size: 4rem;
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/social_banner.jpg");
  background-position-x: 25%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.classification > div {
  min-height: 300px;
}
.classification > div {
  transform: skew(5deg);
  overflow: hidden;
}
.classification .left.image {
  background-image: url("../../assets/img/join/social-01.jpg");
  opacity: 0.8;
}
.classification .right.image {
  background-image: url("../../assets/img/join/social-02.jpg");
  opacity: 0.8;
}
.classification {
  .left {
    width: 50%;
    transform-origin: bottom;
  }

  .right {
    width: 50%;
    transform-origin: top;
  }

  .color {
    background-color: $--ft-white;
    position: absolute;
  }

  .skew-element {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    transform: skewx(-5deg);
  }
  .text {
    position: relative;

    font-size: 1.2rem;
    left: 0;
    top: 0;
    width: 80%;
    margin: 0 auto;
    padding: 3rem 2.5rem;
    transform: skew(-5deg);
  }
}
.plan img {
  width: 8rem;
}
.plan {
  text-align: center;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .classification * {
    transform: skewX(0) !important;
  }
  .classification > div {
    width: 100% !important;
    margin: 0 !important;
  }
  .classification .second-row {
    margin-top: 3rem !important;
  }
  .classification .text {
    font-size: 1rem;
    top: 0%;
    text-align: left;
    width: 100%;
    margin: unset;
  }
  .mobile.left-container {
    height: 330px;
    .title {
      padding-left: unset !important;
    }
  }
  .mobile.right-container {
    height: 220px;
    min-height: unset;
  }
}
</style>
